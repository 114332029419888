
import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { WhatsAppOutlined } from '@ant-design/icons';
import './WhatsAppButton.css';


const WhatsAppButton = () => {
  const [buttonSize, setButtonSize] = useState('large');

  useEffect(() => {
    const handleResize = () => {
      // Verifique a largura da tela e defina o tamanho do botão com base nela
      if (window.innerWidth <= 768) {
        setButtonSize('middle'); // Altere o tamanho para 'middle' em telas menores
      } else {
        setButtonSize('large'); // Mantenha 'large' em telas maiores
      }
    };

    // Adicione um ouvinte de redimensionamento para ajustar o tamanho do botão
    window.addEventListener('resize', handleResize);

    // Chame handleResize inicialmente para definir o tamanho correto
    handleResize();

    // Remova o ouvinte de redimensionamento ao desmontar o componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
    const phoneNumber = '+5517991804957'; 
  const message = 'Olá, estou interessado em seus serviços para e-commerce.'; 
  const handleWhatsAppClick = () => {
    const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
  
    window.open(whatsappURL, '_blank');
  };

  return (
    <div className="whatsapp-button">
      <Button
        type="primary"
        shape="circle"
        icon={<WhatsAppOutlined />}
        size="large"
        onClick={handleWhatsAppClick}
      />
    </div>
  );
};

export default WhatsAppButton;
