import React from 'react';
import { Layout, Menu, Button, Row, Col, Card } from 'antd';
import WhatsAppButton from './WhatsAppButton';
import './App.css';

const { Header, Content, Footer } = Layout;



function App() {

  const isMobile = window.innerWidth < 768;

  return (
    <Layout className="home">
      <Header>
        <div className="container">
          <Row span={12} justify="space-between">
            <Col xs={12} sm={12} md={12} lg={12} xl={12} >
              <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['sobre']}>
                <Menu.Item key="logo">Logo</Menu.Item>
                <Menu.Item key="sobre"><a href="#sobre" className="menu-link">Sobre</a></Menu.Item>
                <Menu.Item key="planos"><a href="#planos" className="menu-link">Planos</a></Menu.Item>
                <Menu.Item key="contato"><a href="#contato" className="menu-link">Contato</a></Menu.Item>
                <Menu.Item key="demonstracao"><a href="#demonstracao" className="menu-link">Demonstração</a></Menu.Item>
              </Menu>
            </Col>
            <Col>
              <Button className={isMobile ? 'button-small-font' : ''} xs={2} sm={2} md={2} lg={4} xl={2} type="primary">Criar loja agora</Button>
            </Col>
          </Row>
        </div>
      </Header>

      <Content style={{ background: 'white' }}>
        <section id="sobre" className="section banner" style={{ background: 'white', height: '500px', display: 'flex', alignItems: 'center' }}>
          <div className="container margin-top">
            <Row span={12} gutter={16}>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <div className="promo-text">
                  <h1 >Tudo o que você precisa para a sua loja virtual</h1>
                  <h3>Em um único lugar: o seu painel</h3>
                  <p>Somos uma empresa especializada em fornecer soluções de e-commerce para o seu negócio. Oferecemos planos anuais, mensais e trimestrais para atender às suas necessidades.</p>
                  <Button type="primary">Criar Loja Agora</Button>
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <div className="promo-image">
                  
                  <img className="img-responsive" src="/images/vendas.jpg" alt="Imagem Promocional" />
                </div>
              </Col>
            </Row>
          </div>
        </section>

        
        <section id="demonstracao" className="section" style={{ marginTop: '100px' }} >
          <div className="container margin-top">
            <Row span={12} gutter={16}>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <div className="ecommerce-image">
                  {/* Substitua o src com a URL da imagem do e-commerce */}
                  <img className="img-responsive" src="/images/demo.jpg"  alt="Imagem de E-commerce" />
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <div className="demo-text">
                  <h2>Veja nosso Projeto de Demonstração</h2>
                  <p>Explore nosso projeto de demonstração para ver como nossa solução de e-commerce pode ajudar o seu negócio. Clique no botão abaixo para começar.</p>
                  <a href="https://plashnatural.dryhub.com.br/" target="_blank" rel="noopener noreferrer">
                    <Button type="primary">Projeto de Demonstração</Button>
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <section id="planos" className="section" style={{ marginTop: '100px' }}>
          <div className="container margin-top" >
            <h2 className="centered-title">Planos e preços para o seu negócio</h2>
            <Row gutter={16}>
              <Col xs={24} sm={8}>
                <Card title="Plano  Mensal">
                  <p className="price big-price">
                    <span className="price-amount">R$ 119</span>
                    <span className="price-suffix">/mês</span>
                  </p>
                  <p>em até 2x sem juros</p>
                  <Button type="primary">Criar Loja</Button>
                  <ul className="features">
                    <li>Relatorios avançados</li>
                    <li>Suporte via e-mail e ticket</li>
                    <li>Integração nativa com Bling</li>
                    <li>Responsivo para celular</li>
                    <li>Dominio Próprio</li>
                  </ul>
                </Card>
              </Col>
              <Col xs={24} sm={8}>
                <Card title="Plano Trimestral">
                  <p className="price big-price">
                    <span className="price-amount">R$ 99</span>
                    <span className="price-suffix">/mês</span>
                  </p>
                  <p>em até 2x sem juros</p>
                  <Button type="primary">Criar Loja</Button>
                  <ul className="features">
                    <li>Relatorios avançados</li>
                    <li>Suporte via e-mail e ticket</li>
                    <li>Integração nativa com Bling</li>
                    <li>Responsivo para celular</li>
                    <li>Dominio Próprio</li>
                  </ul>
                </Card>
              </Col>
              <Col xs={24} sm={8}>
                <Card title="Plano Anual">
                  <p className="price big-price">
                    <span className="price-amount">R$ 89</span>
                    <span className="price-suffix">/mês</span>
                  </p>
                  <p>em até 2x sem juros</p>
                  <Button type="primary">Criar Loja</Button>
                  <ul className="features">
                    <li>Relatorios avançados</li>
                    <li>Suporte via e-mail e ticket</li>
                    <li>Integração nativa com Bling</li>
                    <li>Responsivo para celular</li>
                    <li>Dominio Próprio</li>
                  </ul>
                </Card>
              </Col>
            </Row>
          </div>
        </section>
        <WhatsAppButton span={4} /> {/* Adicione o botão do WhatsApp ao seu layout */}
      </Content>

      <Footer id="contato" className="section bg-dark text-light">
        <div className="container">
          <h3>Entre em Contato</h3>
          <p>Para mais informações, entre em contato conosco:</p>
          <p>Email: vinicio.magalhaes@hotmail.com</p>
          <p>Telefone: (17) 99180-4957</p>
        </div>
      </Footer>

    </Layout>
  );
}

export default App;
